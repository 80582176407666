import React from "react";
import Form from "../components/Form";

import Layout from "../components/Layout";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="has-background-white">
          <div>
            <div className="content" style={{ textAlign: "center" }}>
              <div>
                <div
                  className="section has-background-primary columns"
                  style={{ textAlign: "left" }}
                >
                  <div className="column is-8 is-offset-2">
                    <p className="is-size-5 is-color-white">
                      <span
                        style={{ textDecoration: "underline" }}
                        className="is-color-white"
                      >
                        Energy Talking Points On Demand
                      </span>{" "}
                      is a free service by Alex Epstein to give elected
                      officials powerful, current, well-referenced messaging on
                      energy, environmental, and climate issues.
                    </p>
                    <p className="is-size-5 is-color-white">
                      This service, including our weekly Monday meeting, is
                      available to pro-energy, pro-freedom elected officials and
                      staff from US Senate, US House, and Governor's offices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section columns">
                <Form />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
