import React, { useState } from "react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Field = ({ text, type = "text", handleChange, state }) => {
  const name = text
    .split(" ")
    .map((t) => t.toLowerCase())
    .join("_");

  return (
    <div className="field column is-6">
      <label className="label">{text}*</label>
      <div className="control">
        <input
          className="input"
          type={type}
          required
          onChange={handleChange}
          value={state[name] || ""}
          name={name}
        />
      </div>
    </div>
  );
};

const Form = () => {
  const [state, setState] = useState({});
  const handleChange = (e) => {
    debugger;
    const newState = { ...state, [e.target.name]: e.target.value };
    setState(newState);
  };

  const tracker = { handleChange, state };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setTimeout(() => setState({ success: true }), 100);
      })
      .catch(() => {
        setTimeout(() => setState({ error: true }), 100);
      });
  };

  return (
    <div className="column is-6 is-offset-3">
      <h3 className="column">Sign up below:</h3>

      {state.success ? (
        <p style={{ marginBottom: 20, fontStyle: "italic" }}>
          Your information was submitted successfully.
        </p>
      ) : null}

      {state.error ? (
        <p style={{ marginBottom: 20, fontStyle: "italic" }}>
          Something went wrong. Please{" "}
          <a
            href="mailto:alex@alexepstein.com"
            target="black"
            rel="noopener noreferrer"
          >
            contact Alex directly
          </a>
          .
        </p>
      ) : null}

      <form
        name="contact"
        method="post"
        className="form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="columns">
          <Field text="First Name" {...tracker} />
          <Field text="Last Name" {...tracker} />
        </div>

        <div className="columns">
          <Field
            text="Email"
            type="email"
            handleChange={handleChange}
            state={state}
          />
          <Field text="Position" {...tracker} />
        </div>

        <div className="field">
          <label className="label">Office*</label>
          <div className="control">
            <div className="select">
              <select
                name="office"
                defaultValue=""
                required
                onChange={handleChange}
              >
                <option value="" disabled></option>
                <option value="US House">US House</option>
                <option value="US Senate">US Senate</option>
                <option value="Governor's Offices">Governor's Offices</option>
              </select>
            </div>
          </div>
        </div>

        <div className="columns" style={{ marginTop: "30px" }}>
          <Field text="Elected Official First Name" {...tracker} />
          <Field text="Elected Official Last Name" {...tracker} />
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
